import React, { useState } from "react";
import clsx from "clsx";
import {
  Grid,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { ReactComponent as DotMenu } from "../../../assets/icons/dots-horizontal.svg";
import { useConfigContext } from "../../../_context/config.context";
import botImg from "../../../assets/images/header_icon.png";
import useStyles, { Badge, Tooltip } from "../styles/headerStyles";

/**
 * Use `Header` to provide a source of info for the bot.
 */

export default function Header({
  handleEndChatBtn,
  sendMessage,
  toggleOpenState,
}) {
  const { config } = useConfigContext();
  const classes = useStyles(config);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    HEADER_MENU,
    HEADER_MENU_ITEMS,
    ENABLE_FEEDBACK,
    HEADER_MENU_FOLDED,
    HEADER_BADGE_STYLE,
    HEADER_SHOW_NAME,
  } = config;

  const triggerPostBack = (item) => {
    sendMessage({
      title: item.label,
      payload: item.payload,
      text: item.label,
    });
  };

  const handleMenu = (e) => {
    anchorEl === null ? setAnchorEl(e.currentTarget) : setAnchorEl(null);
  };

  const endChat = (e) => {
    handleMenu(e);
    setTimeout(() => {
      handleEndChatBtn(ENABLE_FEEDBACK);
    }, 100);
  };

  return (
    <Grid
      item
      xs={12}
      className={classes.header}
      data-testid="header-container"
    >
      <Grid className={classes.headerTitle}>
        <Badge
          overlap={HEADER_BADGE_STYLE}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          {HEADER_BADGE_STYLE === "rectangle" ? (
            <img src={botImg} alt={config.BOT_NAME} height={40} style={{height: '40px'}}/>
          ) : (
            <Avatar alt="Stock avatar" src={botImg} />
          )}
        </Badge>
        {HEADER_SHOW_NAME && (
          <div className={classes.headerText} data-testid="header-bot-name">
            {config.BOT_NAME}
          </div>
        )}
      </Grid>
      {HEADER_MENU && HEADER_MENU_FOLDED && (
        <DotMenu
          className={classes.ellipsisIcon}
          onClick={handleMenu}
          data-testid="header-menu-btn"
        />
      )}
      {HEADER_MENU_FOLDED ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenu}
          classes={{
            list: classes.menuList,
            paper: classes.menuList,
          }}
          style={{ zIndex: 2000 }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div data-testid="header-menu-item-container">
            {HEADER_MENU_ITEMS.map((item, i) => (
              <MenuItem
                dense
                className={classes.menuItem}
                onClick={(e) =>
                  item.payload === "end"
                    ? endChat(e)
                    : item.payload === "minimise"
                    ? handleMenu(e)
                    : triggerPostBack(item)
                }
                key={i}
              >
                <i className={clsx(item.icon, classes.menuItemIcon)}></i>
                {item.label}
              </MenuItem>
            ))}
          </div>
        </Menu>
      ) : (
        <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.inlineMenu}>
          {HEADER_MENU_ITEMS.map((item, i) => (
            <Tooltip
              title={item.label}
              placement="top"
              key={i}
            >
              <IconButton
                className={classes.inlineMenuItem}
                onClick={(e) =>
                  item.payload === "end"
                    ? endChat(e)
                    : item.payload === "minimise"
                    ? toggleOpenState()
                    : triggerPostBack(item)
                }
              >
                <i className={clsx(item.icon, classes.inlineMenuItemIcon)}></i>
              </IconButton>
            </Tooltip>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
