import React, { useRef, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import clsx from 'clsx'
import QrExtraOptions from './qrExtraOptions'
import QuickReply from './quickReply'
import useStyles from '../styles/messageListStyles'
import {useConfigContext} from '../../../_context/config.context'

import Message from '../../Message'

export default function MessageList({
  messages,
  windowSize,
  sendMessage,
  loading,
  focus,
  webSocket,
  notificationVisible,
  setNotificationVisible
}) {
  const {config} = useConfigContext()
  const {WEBSHARE_IGNORED_QRS} = config
  const chatBottomRef = useRef(null);
  const qrHeightRef = useRef(null)
  const lastBotRef = useRef(null)
  const [canShare, setCanShare] = useState(false)
  const [qrHeight, setQrHeight] = useState(0)
  const [extraOptionsOpen, setExtraOptionsOpen] = useState(false)
  const classes = useStyles({ ...windowSize, ...config, qrHeight});

  const toggleOptionsOpen = e => {
    if(e.target.id === 'toggle-backdrop') setExtraOptionsOpen(!extraOptionsOpen)
  }

  const lastIndexOf = () => {
    for(let i = messages.length - 1; i >= 0; i--){
      if(messages[i].createdBy === 'user')
        return i;
    }
    return -1;
  };

  useEffect(() => {
    setQrHeight(qrHeightRef?.current?.clientHeight ?? 0)
  }, [messages])

  useEffect(() => {
    if(navigator.share && !canShare) setCanShare(true)
  }, [setCanShare, canShare])

  useEffect(() => {
    /* scroll to bottom whenever chat is rerendered- new message, or new notifcation etc*/
    /* old configuration: scrollPos = chatBottomRef.current.scrollHeight */
    const isIE11 = document.documentMode || /Edge/.test(navigator.userAgent)
    const scrollPos = chatBottomRef?.current?.scrollHeight
    isIE11 ? chatBottomRef.current.scrollTop = scrollPos
    :
    chatBottomRef?.current?.scrollTo && chatBottomRef.current.scrollTo({top: scrollPos, behavior: 'smooth'}) // added && because of unit testing
  },[focus])

  useEffect(() => {
    /* scroll to bottom whenever chat is rerendered- new message, or new notifcation etc*/
    /* old configuration: scrollPos = chatBottomRef.current.scrollHeight */
    const isIE11 = document.documentMode || /Edge/.test(navigator.userAgent)
    const scrollPos = lastBotRef?.current?.offsetTop > 0 ? lastBotRef?.current?.offsetTop : 0
    isIE11 ? chatBottomRef.current.scrollTop = scrollPos
    :
    setTimeout(() => {
     chatBottomRef?.current?.scrollTo && chatBottomRef.current.scrollTo({top: scrollPos, behavior: 'smooth'})
   }, 0)
    // added && because of unit testing,
    //timeout added as height of chatarea was being modified pre render due to change to QR rendering logic
    // if no timeout is provided, the scroll will not reach the bottom of div
  },[messages, loading])

  const lastMessage = messages[messages.length - 1]?.data
  const qrs = lastMessage?.quickReplies ?  lastMessage?.quickReplies : []
  const options = canShare ? qrs : qrs.filter(x => !WEBSHARE_IGNORED_QRS.includes(x.payload))

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} className={clsx(classes.messageContainer, options.length > 0 && 'showQr')} ref={chatBottomRef}>
        <TransitionGroup>
          {messages.map((msg, i) => (
            <CSSTransition //transition component to animate the messages being rendered onto UI
              key={msg.id}
              timeout={800} //delay for transtions
              classNames={
                msg.type === 'postback' && msg.createdBy === 'user' ?
                classes.postBackMsgAnimation :
                msg.createdBy === 'bot' ?
                classes.botMsgAnimation :
                classes.userMsgAnimation
              }
            >
              <div ref={i === lastIndexOf() ? lastBotRef : null}>
                <Message
                  {...msg}
                  webSocket={webSocket}
                  windowSize={windowSize}
                  sendMessage={sendMessage}
                  nextMsg={messages[i +1]}
                  isLast={i === messages.length - 1}
                  toggleOpen={toggleOptionsOpen}
                  notificationVisible={notificationVisible}
                  setNotificationVisible={setNotificationVisible}
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        {
          loading
          &&
          <Grid item data-testid='typing-indicator'>
            <span className={classes.typingDot}></span>
            <span className={classes.typingDot}></span>
            <span className={classes.typingDot}></span>
          </Grid>
        }
      </Grid>
      { /*show quick replies if available */
        options.length > 0
        &&
        <QuickReply quickReplies={options} sendMessage={sendMessage} toggleOpen={toggleOptionsOpen} ref={qrHeightRef} />
      }
      <QrExtraOptions
        toggleOpen={toggleOptionsOpen}
        open={extraOptionsOpen}
        options={options}
        windowSize={windowSize}
        sendMessage={sendMessage}
      />
    </Grid>
  );
}
