import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import {Button} from '@material-ui/core'
import { ReactComponent as LeftArrow } from '../../../assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/chevron-right.svg';
import useStyles from '../styles/genericMessageStyles'
import Flicking from "@egjs/react-flicking";
import formatter from "../../../_helpers/formatter";
import {webShare} from "../../../_helpers/share";

export default function GenericMessage({ data, sendMessage, config, windowSize, webSocket }) {
  const { FULLSCREEN_SMALL_WIDGET } = config
  const { elements } = data
  const classes = useStyles(config);
  const initialIndex = 0;
  const [activeItemIndex, setActiveItemIndex] = useState(initialIndex);
  const [canShare, setCanShare] = useState(false)
  const flick = useRef(null)

  const handleButtonClick = btnInfo => {
    if (btnInfo.type === 'postback') {
      btnInfo.text = btnInfo.title
      sendMessage(btnInfo)
    } else if (btnInfo.type === 'url' || btnInfo.type === 'web_url') {
      if(btnInfo.url.includes('tel:'))
        window.location = btnInfo.url
      else
        window.open(btnInfo.url, '_blank');
    }
  };

  const triggerShare = shareData => {
    webShare(shareData);
  }

  const changePanel = dir => {
    if(dir === 'prev') {
      flick.current.prev()
    } else {
      flick.current.next()
    }
  }

  useEffect(() => {
    if(navigator.share && !canShare) setCanShare(true)
  }, [setCanShare, canShare])

  const maxLength = elements.length - 1
  return ( FULLSCREEN_SMALL_WIDGET? 
    <div 
        data-testid='generic-template-container'
        zIndex={1900} className={classes.flatDisplay}>{
          elements.map((e, i) =>
          <div key={i} className={clsx(classes.externalContainer, !e.imageUrl && 'noImage')}>
            <div
              data-testid={`generic-card-${i}`}
              className={clsx(
                classes.internalContainer,
                activeItemIndex === i && 'active' ,
                !e.imageUrl && 'noImage',
                (!e.subtitle || e.subtitle?.length === 0) && 'noContent'
              )}>
                {
                  e.imageUrl &&
                  <div
                    className='-image'
                    style={{
                      backgroundImage: `url(${e.imageUrl})`
                    }}
                  />
                }
                <div className={classes.detailsContainer}>
                  <p className={classes.detailsContainerTitle}>{formatter(e.title)}</p>
                  <h5 className={classes.detailsContainerTitleSubTitle}>{formatter(e.subtitle)}</h5>
                </div>
                <div className={classes.buttonsContainer}>
                  {e.buttons.map((b, index) =>
                    <Button
                      style={{
                        border: `1px solid ${e.color}`, // these two options added so that generic template colors can be modified independently
                        color: `${e.color}`
                      }}
                      key={index}
                      className={classes.actionButton}
                      onClick={() => handleButtonClick(b)}
                      disabled={!webSocket}
                    >
                      {formatter(b.title)}
                    </Button>
                  )}
                </div>
              </div>
              {canShare &&
                e.shareData &&
                  elements.length === 1 &&
                    <Button className='shareButton' onClick={() => triggerShare(e.shareData)}>
                      <i className="fas fa-share" />
                    </Button>
              }
            </div>
          )
        }</div>:
    <div className={classes.root}>
      <button className={clsx(classes.arrowButtons, activeItemIndex === 0 && 'hide')} onClick={()=> changePanel('prev')}>
        <LeftArrow />
      </button> 
      <Flicking
        data-testid='generic-template-container'
        ref={flick}
        tag = "div"
        horizontal = {true}
        duration={300}
        defaultIndex = {activeItemIndex}
        onMoveEnd = {e => setActiveItemIndex(e.index)}
        infinite={false}
        bound = {false}
        // adaptive={true} enable this to allow it to adapt to card size but it causes jittery movement
        overflow = {false}
        gap = {2}
        zIndex={1900}
        collectStatistics = {false}
        className={classes.flick}
      >
        {
          elements.map((e, i) =>
          <div key={i} className={clsx(classes.externalContainer, !e.imageUrl && 'noImage')}>
            <div
              data-testid={`generic-card-${i}`}
              className={clsx(
                classes.internalContainer,
                activeItemIndex === i && 'active' ,
                !e.imageUrl && 'noImage',
                (!e.subtitle || e.subtitle?.length === 0) && 'noContent'
              )}>
                {
                  e.imageUrl &&
                  <div
                    className='-image'
                    style={{
                      backgroundImage: `url(${e.imageUrl})`
                    }}
                  />
                }
                <div className={classes.detailsContainer}>
                  <p className={classes.detailsContainerTitle}>{formatter(e.title)}</p>
                  <h5 className={classes.detailsContainerTitleSubTitle}>{formatter(e.subtitle)}</h5>
                </div>
                <div className={classes.buttonsContainer}>
                  {e.buttons.map((b, index) =>
                    <Button
                      style={{
                        border: `1px solid ${e.color}`, // these two options added so that generic template colors can be modified independently
                        color: `${e.color}`
                      }}
                      key={index}
                      className={classes.actionButton}
                      onClick={() => handleButtonClick(b)}
                      disabled={!webSocket}
                    >
                      {formatter(b.title)}
                    </Button>
                  )}
                </div>
              </div>
              {canShare &&
                e.shareData &&
                  elements.length === 1 &&
                    <Button className='shareButton' onClick={() => triggerShare(e.shareData)}>
                      <i className="fas fa-share" />
                    </Button>
              }
            </div>
          )
        }
      </Flicking>
      <button className={clsx(classes.arrowButtons, 'rightArrow', activeItemIndex === maxLength && 'hide')} onClick={()=> changePanel('next')}>
        <RightArrow />
      </button>
    </div>
  );
}
