import React, {useState, useEffect} from 'react';
import clsx from 'clsx'
import {Button} from '@material-ui/core'
import moment from 'moment'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import Sanitize from 'sanitize-html'
import { ReactComponent as TickIcon } from '../../../assets/icons/tick.svg'
import listFilter from '../../../_helpers/listFilter'
import useStyles from '../styles/textMessageStyles'
import formatter, {formatterString} from "../../../_helpers/formatter";
import {webShare} from "../../../_helpers/share";

export default function TextMessage({ nextMsg, config, ...props }) {
  const {BOT_TEXT_MAX_LINES, GLOBAL_COLOR} = config
  const [canShare, setCanShare] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [truncated, setTruncated] = useState(false)
  const classes = useStyles(config);
  const { createdBy, data, createdAt, readState } = props
  const { text, title, shareData } = data

  const triggerShare = () => {
    webShare(shareData);
  }

  const toggleExpanded = () => {
    setExpanded(current => !current)
  }

  const handleReflow = reflow => {
    const { clamped } = reflow
    setTruncated(clamped)
  }

  useEffect(() => {
    if(navigator.share && !canShare) setCanShare(true)
  }, [setCanShare, canShare])

  const defaultTags = Sanitize.defaults.allowedTags
  const iframeIndex = Sanitize.defaults.allowedTags.indexOf('iframe')
  if(iframeIndex !== -1) Sanitize.defaults.allowedTags.splice(iframeIndex, 1)
  const allowedTags = defaultTags.concat([ 'img', 'h1', 'h2', 'sup' ])
  const allowedSchemes = [ 'https', 'ftp', 'mailto', 'tel' ]
  const allowedAttributes = {
    a: [ 'href', 'name', 'target', 'sty;e' ], img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ], 
    p: ['style'], div: ['style'], span: ['style']}

  const textContainer = ({children}) => {
    const isList = (children?.type === 'ul') || (Array.isArray(children) && children.findIndex(item => item?.type === 'ul') !== -1)
    const formattedHTMLString = isList ? children : formatterString(children);
    const sanitizedText = !isList && Sanitize(formattedHTMLString, {
      allowedTags, allowedSchemes, allowedAttributes
    });
    return (
      <div className={classes.internalText}>
        {
          isList
          ?
          children.filter((child) => child !== "").map((child) => child?.type === 'ul' || child === "\n"? child:<span dangerouslySetInnerHTML={{__html: Sanitize(formatterString(child), {
            allowedTags, allowedSchemes, allowedAttributes
          })}} />)
          :
          expanded
          ?
          <div dangerouslySetInnerHTML={{__html: sanitizedText}} />
          :
          <div>
            <HTMLEllipsis
              unsafeHTML={sanitizedText}
              maxLine={BOT_TEXT_MAX_LINES}
              ellipsis={null}
              onReflow={handleReflow}
            />
            {
              truncated &&
              <div onClick={toggleExpanded} style={{ fontWeight: 'bold', cursor: 'pointer', color: GLOBAL_COLOR}}>read more...</div>
            }
          </div>
        }
      </div>
    )
  }

  /*
    nextMsgSourceIsUser & nextMsgSourceIsBot is used to determine whether the border radius should be bigger or default,
    this provides the user messages with a more elegant feel
   */
  const nextMsgSourceIsBot = nextMsg ? nextMsg.createdBy === 'bot' : null
  const nextMsgSourceIsUser = nextMsg ? nextMsg.createdBy === 'user' : null

  return (
      createdBy === 'bot' || createdBy === 'agent'
      ?
      <div data-testid='bot-text-message'>
        <div className={clsx(classes.messageContainer, 'botMessage', {
          'lastBotMessage': nextMsgSourceIsUser // Increases border-bottom-left-radius if its the last bot message in the chat
        })}>
          {listFilter(text, textContainer, classes)}
          <span className={classes.botTimeStamp}>{moment(createdAt).format("h:mm a")}</span>
          {canShare &&
            shareData &&
              <Button className='shareButton' onClick={triggerShare}>
                <i className="fas fa-share" />
              </Button>
          }
        </div>
        <div className={classes.clearDiv} />
      </div>
      :
      <div data-testid='user-text-message'>
        <div className={clsx(classes.messageContainer, 'userMessage', {
          'lastUserMessage': nextMsgSourceIsBot, // Increases border-bottom-right-radius if its the last user message in the chat
          'lastUserMessageInChat' : nextMsg === undefined //this allows us to control whether its the styling for the user message before an bot message or the last message in the chat
        })}>
          <div className={classes.internalText}>
            {formatter(text ? text : title)}
          </div>
          <span className={classes.userTimeStamp}>{moment(createdAt).format("h:mm a")} {readState && <TickIcon className='read-icon' />}</span>
        </div>
        <div className={classes.clearDiv} />
      </div>
  );
}
